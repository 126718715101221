.parceiros {
    padding-bottom: 30px;
    .card-list > *{
        display: flex;
    }
    .card{
        position: relative;
        padding-bottom: 55px;
    }

    .card-footer{
        position: absolute;
        bottom: 0px;
        width: 100%;
    }



    .card-body{
        text-align: center;
    }

    .card-title{
        font-weight: bold;
        color: $azul;
        text-align: center;
        font-size: 18px;
        margin: 0;
    }
}