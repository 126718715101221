.sobre-empresa{
    p{
        margin-bottom: 40px;
        color: #6b6b6b;
        font:{
            weight: 300;
            size: 16px;
        };
    }
}


.galeria-de-foto{
    .item-maior-foto{
        .item-foto-maior{
            width: 100%;
            height: auto;
            iframe{
                width: 100%;
            }
        }
    }

    .fotos-menores{
        .item-fotos{
            height: 60px;
            cursor: pointer;
            margin-left: -5px;
            text-align: center;
            img{
                display: inline-block;
                max-width: 100%;
                height: 100%;
            }
        }
    }
}

/*************************************************/
/*************************************************/


/* Mobile */

@media screen and (min-width: 320px) {
    .galeria-de-foto{
        margin-bottom: 30px;
    }
    .missao,
    .valores{
        height: inherit;
        padding-bottom: 30px;
    }

    .botoes-entre-em-contato{
        margin-top: 20px;
    }
}


@media screen and (min-width: 540px) {

}


@media screen and (min-width: 768px) {

}

@media screen and (min-width: 992px) {

}

@media screen and (min-width: 1200px) {
    .galeria-de-foto{
        margin-bottom: 0;
    }

    .missao,
    .valores{
        height: 300px;
        padding-bottom: 0;
    }

    .botoes-entre-em-contato{
        margin-top: 0;
    }
}

@media screen and (min-width: 1400px) {

}

.espaco-loja-grande{
    max-width: 400px;
}
.espaco-loja-pequeno{
    max-width:100px;
}
