/*===========================================
	Common
============================================*/
@import 'sections/_common.scss';
/*===========================================
	Home
============================================*/
@import 'sections/_home.scss';
/*===========================================
	Slider
============================================*/
@import 'sections/_slider.scss';
/*===========================================
	Noticias
============================================*/
@import 'sections/_noticias.scss';
/*===========================================
	Associados
============================================*/
@import 'sections/_associados.scss';
/*===========================================
  Institucional
============================================*/
@import 'sections/_institucional.scss';
/*===========================================
	Contato
============================================*/
@import 'sections/_contato.scss';
/*===========================================
	Seja Associado
============================================*/
@import 'sections/_seja-associado.scss';
/*===========================================
	Ouvidoria
============================================*/
@import 'sections/_ouvidoria.scss';
/*===========================================
	Parceiros
============================================*/
@import 'sections/_parceiros.scss';
/*===========================================
	Contato
============================================*/
@import 'sections/_blog.scss';
