.blog{
    padding-bottom: 50px;
    color: #333;

    article{
        margin-bottom: 20px;
        border-bottom: solid 1px #9e9e9e;
        padding-bottom: 22px;

        .post-share{
            background-color: #4caf50;
            color: #fff;
            padding: 10px 15px;
        }
        .post-share:hover{
            background-color: #479e4b;
        }
        .custom-btn {
            margin-top: 10px;
        }
        &:last-child{
            border-bottom: none;
        }
        
        @media screen and (max-width: 575px){
            > div:first-child{
                height: 240px;
                margin-bottom: 5px;
            }
            .post-title{
                font-size: 1.2em;
            }
            .post-author{
                margin-bottom: 10px;
            }
        }
    }


    .post-title{
        font-size: 24px;
        margin: 0px auto 10px;
        text-align: left;
        color: #333;
        text-transform: uppercase;
        font-weight: bold;
        a{
            color: inherit;
        }
    }
    
    .post-body{
        text-align: justify;
    }

    .post-author {
        font-size: 12px;
        font-weight: 400;
        margin: 0 0 20px;
        color: #bbb;
    }
    .post-image{
        width:100%;
        margin: 0px 0px 10px 0;
        
        img{
            width: 100%;
        }
        
        @media screen and (min-width: 576px){
        }
        @media screen and (min-width: 768px){
            width: 35%;
            margin: 0px 30px 15px 0px;
            float: left;
        }
    }
}