.form-seja-associado{
    input[type=text],input[type=email],select{
        @include radius(0);
        margin-bottom: 20px;
        display: inline-block;
        height: 40px;
    }
    
    @media screen and (min-width: 768px) {
        position: sticky;
        top: 5px;
    }
    
    

}