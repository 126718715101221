/* 320px */
@media screen and (min-width: 320px) {

    .box-cta-botao{
        width: 100%;
        position: fixed;
        bottom: 30px;
        z-index: 100000;
        text-align: center;

        .botao-tenho-interesse-lead{
            text-transform: uppercase;
            font-weight: 900;
            font-size: 12px;
            color: $branco;
            background: $green;
            padding: 12px;
            padding-left: 15px;
            padding-right: 15px;
            @include radius(12px);
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
        }
    }

    .associados{

        .detalhes{

            ul{

                display: none;

            }

            .btn-detalhes{
                display: block;
            }

        }

    }

}

@media screen and (min-width: 540px) {

}

@media screen and (min-width: 768px) {

    

}

@media screen and (min-width: 992px) {

}

@media screen and (min-width: 1200px) {

    .associados{

        .detalhes{

            ul{

                display: block;

            }

            .btn-detalhes{
                display: none;
            }

        }

    }

}

@media screen and (min-width: 1400px) {

}
