.associados {
    padding-bottom: 30px;
    
    .card-img{
        height: 0;
        position: relative;
        padding-bottom: 100%;
        display: block;
        overflow: hidden;

        img{
            width: 100%;
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
    .card-title{
        font-weight: bold;
        color: $azul;
        text-align: center;
        font-size: 18px;
        margin: 0;
    }
}