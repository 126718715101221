.form-contato{
    input{
        @include radius(0);
        margin-bottom: 20px;
        display: inline-block;
        height: 40px;
    }

    textarea{
        @include radius(0);
        margin-bottom: 20px;
        display: inline-block;
    }

}

.informacoes-da-pagina-de-contato{
    text-align: center;
    margin-bottom: 100px;
    .email-contato,
    .numero-contato,
    .numero-whatsapp{
        margin-right: 30px;
        color: $azul;
        font-size: 14px;
        font-weight: 300;
        strong{ font-weight: 800; }
    }
}


/*************************************************/
/*************************************************/


/* Mobile */

@media screen and (min-width: 320px) {
    .informacoes-da-pagina-de-contato{
        text-align: left;
        span{
            display: inline-block;
            margin-right: 0;
            width: 100%;
            margin-bottom: 20px;
        }
    }
}


@media screen and (min-width: 540px) {

}


@media screen and (min-width: 768px) {

}

@media screen and (min-width: 992px) {
    .informacoes-da-pagina-de-contato{
        text-align: left;
        span{
            display: inline-block;
            margin-right: 0;
            width: inherit;
            margin-bottom: 20px;
        }
    }
}

@media screen and (min-width: 1200px) {

}

@media screen and (min-width: 1400px) {

}
